
// DATA SECTION MEDIA QUERY

@media(min-width:760px){
    .data__sections--wrap{
        grid-template-areas:
        "pie league"
        "player league"
        "team league"
        "minute minute";
        grid-template-rows: repeat(4,1fr);
        gap: 15px;
        grid-template-columns: 50%;
        // display: none;
    }
    .pie__chart--wrap{
        height: 100%;
    }
    .pie__chart--title{
        height: 25%;
    }

    .league__table--name{
        bottom: 0px;
    };

    // .league__table--content{
    //     .league-table{
    //         table{
    //             width: 100%;
    //             height: 100%
    //         }
    //     }
    // }
    
    .player__stats--title{
        h4{
            font-size: 0.9rem
        }
    }
    .team__stats--title{
        h4{
            font-size: 0.9rem
        }
    }
    
    .minute__stat--box{
        h4{
            font-size: 0.9rem
        }
    }
};
