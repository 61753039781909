:root {
  --primary-color: #2b2b2c;
  --secondary-color:rgba(34, 169, 227, 0.25);
  --flair-color: #22A9E3;
  --text-color: #fffcfc;
  --shade-color:rgba(10,9,9, 0.05);
  --fixture-shade-color: rgba(34, 169, 227, 0.25);
  --error-color: #22A9E3;
  transition: 1s;
}

.dark-theme {
  --primary-color: #131326;
  --secondary-color: #222536;
  --flair-color: #C65300;
  --text-color:#F8F9FE;
  --shade-color:rgba(255,255,255, 0.05);
  --fixture-shade-color:rgba(255,255,255, 0.05);
  --error-color: #222536;
  transition: 1s;
}

.data__sections--wrap {
  width: 100%;
  height: 100%;
  display: grid;
  display: none;
  gap: 1.5rem;
  grid-template-areas: "pie" "player" "team" "minute" "league";
  grid-template-rows: 22rem 20rem 20rem 20rem 1fr;
  grid-template-columns: 100%;
}

.pie__chart--wrap {
  grid-area: pie;
  height: 100%;
  width: 100%;
  background-color: var(--secondary-color);
  border-radius: 20px;
  position: relative;
}

.pie__chart--content {
  position: relative;
  height: 100%;
  width: 100%;
  padding: 3%;
}

.player__stats--wrap {
  grid-area: player;
  height: 100%;
  width: 100%;
  border-radius: 20px;
  background-color: var(--secondary-color);
  overflow-y: scroll;
}

.player__stats--wrap::-webkit-scrollbar {
  width: 10px;
  border-radius: 20px;
}

.player__stats--title {
  height: 15%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.player__stats--title h4 {
  font-size: 0.8rem;
  font-weight: 500;
}

.player__stats--table {
  width: 100%;
  height: 85%;
  font-size: 0.64rem;
}

.player__stats--table table {
  width: 100%;
  height: 100%;
  border-collapse: collapse;
}

.player__stats--table td,
.player__stats--table th {
  text-align: center;
  padding: 2px;
}

.player__stats--body tr:nth-child(odd) {
  background-color: var(--shade-color);
}

.team__stats--wrap {
  grid-area: team;
  height: 100%;
  width: 100%;
  background-color: var(--secondary-color);
  border-radius: 20px;
}

.team__stats--title {
  height: 15%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.team__stats--title h4 {
  font-size: 0.8rem;
  font-weight: 500;
}

.team__stats--content {
  height: 85%;
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr;
}

.team__stats--content .team__stats--box {
  text-align: center;
  height: 100%;
  width: 100%;
  padding: 2%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
}

.team__stats--content .team__stats--box h6 {
  font-size: 0.6rem;
}

.team__stats--content .team__stats--box:nth-child(odd) {
  background-color: var(--shade-color);
}

.minute__stat--wrap {
  grid-area: minute;
  height: 100%;
  width: 100%;
  padding: 2%;
  background-color: var(--secondary-color);
  border-radius: 20px;
  position: relative;
  z-index: -10;
}

.minute__stat--content {
  height: 100%;
  width: 100%;
  display: flex;
}

.minute__stat--box {
  height: 100%;
  width: 50%;
  padding: 2%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  text-align: center;
}

.minute__stat--box h4 {
  font-size: 0.8rem;
  font-weight: 500;
}

.minute__stat--percentage {
  position: relative;
  width: 140px;
  margin-top: 2rem;
}

.minute__stat--percentage svg {
  fill: var(--flair-color);
  position: absolute;
  top: -120%;
  left: 5%;
  width: 8rem;
  height: 8rem;
  transition: 1s;
}

.league__table--wrap {
  grid-area: league;
  height: 100%;
  width: 100%;
  background-color: var(--secondary-color);
  border-radius: 20px;
  padding: 10px;
  position: relative;
  z-index: -10;
}

.league__table--name {
  text-align: center;
  height: 5%;
  position: relative;
  bottom: 5px;
}

.league__table--content {
  height: 100%;
  width: 100%;
  text-align: center;
  font-size: 0.8rem;
}

.league__table--content table {
  width: 100%;
  height: 95%;
}

@media (min-width: 760px) {
  .data__sections--wrap {
    grid-template-areas: "pie league" "player league" "team league" "minute minute";
    grid-template-rows: repeat(4, 1fr);
    gap: 15px;
    grid-template-columns: 50%;
  }

  .pie__chart--wrap {
    height: 100%;
  }

  .pie__chart--title {
    height: 25%;
  }

  .league__table--name {
    bottom: 0px;
  }

  .player__stats--title h4 {
    font-size: 0.9rem;
  }

  .team__stats--title h4 {
    font-size: 0.9rem;
  }

  .minute__stat--box h4 {
    font-size: 0.9rem;
  }
}

.main__content {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.nav__slide {
  position: fixed;
  top: 0;
  left: 0;
  width: 40%;
  height: 100%;
  display: flex;
  transform: translateX(-100%);
  transition: 0.5s ease-in-out;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  background-color: var(--secondary-color);
  z-index: 30;
}

.nav__slide ul {
  margin-bottom: auto;
  list-style-type: none;
  width: 100%;
  height: 65%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  text-align: center;
}

.nav__slide ul li {
  width: 100%;
  height: 15%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.nav__slide ul li a {
  text-decoration: none;
  color: var(--text-color);
}

.nav__slide ul li:hover {
  background-color: var(--secondary-color);
  cursor: pointer;
}

.slide__logo {
  margin-bottom: auto;
  width: 100%;
  height: 15%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  position: relative;
}

.slide__logo svg {
  stroke: var(--flair-color);
}

.slide__logo span {
  font-size: 2.5rem;
  position: absolute;
  top: 10px;
  right: 15px;
}

.dateTime {
  border-top: 2px solid var(--secondary-color);
  width: 100%;
  height: 15%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  transition: none;
}

header {
  height: 115vh;
  width: 100%;
  display: grid;
  grid-template-areas: "navT navT navT navT" "navT navT navT navT" ". title title ." "search search search search" ". . . ." "name name name name";
  grid-template-rows: 1fr 1fr 1fr 1fr 1fr 1fr;
}

.nav__top {
  height: 10vh;
  width: 100vw;
  padding: 0 5%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  grid-area: navT;
  position: fixed;
  top: 0;
  right: 0;
  z-index: 2;
  background-color: var(--primary-color);
}

.burger__wrap {
  width: 15%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.burger__wrap span {
  font-size: 3rem;
}

.theme__toggle--wrap {
  height: 2rem;
  width: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
}

.theme__toggle--btn {
  position: relative;
  background-color: var(--shade-color);
  border-radius: 20px;
  width: 100%;
  height: 100%;
}

.theme__toggle--btn .dark__icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 0;
  visibility: hidden;
  transition: 1s ease-out;
}

.theme__toggle--btn .light__icon {
  opacity: 0;
  visibility: hidden;
  transition: 1s ease-out;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.theme__toggle--btn .active__theme--icon {
  opacity: 1;
  visibility: visible;
  transition: 1s ease-out;
}

.theme__toggle--btn:hover {
  cursor: pointer;
}

.title__logo {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  grid-area: title;
}

.logo svg {
  width: 5rem;
  height: 5rem;
  stroke: var(--flair-color);
}

.title h1 {
  font-size: 3rem;
  font-weight: 400;
}

.title span {
  font-style: italic;
  color: var(--flair-color);
}

.search__wrap {
  width: 100%;
  grid-area: search;
  display: flex;
  justify-content: center;
  align-items: center;
}

.search__input {
  width: 90%;
  max-width: 360px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.search__input .search__icon {
  position: absolute;
  left: 2%;
  font-size: 1.2rem;
}

.search__input .search__form {
  width: 100%;
}

.search__input input {
  width: 100%;
  color: var(--text-color);
  padding: 5% 12%;
  border-radius: 40px;
  border: none;
  outline: none;
  background-color: var(--shade-color);
}

::placeholder {
  color: var(--text-color);
}

.search__icon:hover {
  cursor: pointer;
}

.team__name--wrap {
  grid-area: name;
  width: 100%;
  text-align: center;
}

.team__name--wrap h4 {
  font-size: 2.8rem;
}

@media (min-width: 375px) {
  .search__input .search__icon {
    font-size: 1.5rem;
  }
}

@media (min-width: 760px) {
  .logo img {
    width: 6rem;
    height: 6rem;
  }

  .title h1 {
    font-size: 4.5rem;
  }

  .search__input input {
    font-size: 1.1rem;
    padding: 5% 12%;
  }
  .search__input .search__icon {
    font-size: 1.4rem;
  }
}

.history__section--wrap {
  display: none;
  width: 100%;
  height: 100%;
  background-color: var(--secondary-color);
  border-radius: 20px;
  grid-template-areas: ". t t ." "p p p p" "p p p p" "img img img img" "img img img img";
}

.history__section--title {
  padding: 20%;
  grid-area: t;
  text-align: center;
  font-size: 1.3rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.history__section--text {
  grid-area: p;
  height: 100%;
  padding: 5%;
}

.history__section--img {
  grid-area: img;
  padding: 2% 5%;
}

.history__section--img img {
  height: 100%;
  width: 100%;
  object-fit: cover;
  -webkit-object-fit: cover;
  -moz-object-fit: cover;
  object-position: 100% 100%;
  -webkit-object-position: 100% 100%;
  -moz-object-position: 100% 100%;
}

.fixtures__section--wrap {
  display: none;
  grid-template-areas: ". title title ." "cont cont cont cont" "cont cont cont cont" "cont cont cont cont";
  height: 100%;
  width: 100%;
  background-color: var(--secondary-color);
  border-radius: 20px;
}

.fixtures__section--title {
  grid-area: title;
  padding: 5%;
  width: 100%;
  text-align: center;
  font-size: 1.3rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.fixtures__section--content {
  grid-area: cont;
  width: 100%;
  height: 45rem;
  overflow: scroll;
  overflow-x: hidden;
  padding-left: 20px;
}

.fixture {
  background-color: var(--fixture-shade-color);
  width: 75%;
  margin: 15px auto;
  padding: 1%;
  font-size: 0.8rem;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 60px;
}

.prediction__section--wrap {
  height: 100%;
  width: 100%;
  padding: 2%;
  display: none;
  background-color: var(--secondary-color);
  border-radius: 20px;
  grid-template-areas: ". title title ." "pred pred pred pred" "pred pred pred pred" "win win win win" "chart chart chart chart" "chart chart chart chart";
  row-gap: 60px;
  grid-template-rows: 50px repeat(5, minmax(100px, 1fr));
}

.prediction__chart--wrap {
  grid-area: chart;
  height: 100%;
  width: 100%;
  padding: 1px;
}

.prediction__chart--radar {
  position: relative;
  height: 95%;
  width: 95%;
  margin: 0 auto;
}

.prediction__section--title {
  grid-area: title;
  width: 100%;
  height: 100%;
  text-align: center;
  padding-top: 2%;
  font-size: 1.5rem;
}

.prediction__section--content {
  grid-area: pred;
  height: 100%;
  width: 100%;
  padding: 1px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.prediction__section--content table {
  height: 75%;
  width: 100%;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.prediction__section--content table th {
  padding: 0.5rem 0%;
}

.prediction__section--content table td {
  padding: 0.5rem 0;
}

.prediction__section--winner {
  grid-area: win;
  height: 60%;
  width: 100%;
  font-size: 0.8rem;
}

.prediction__section--winner div {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  text-align: center;
  height: 100%;
  width: 100%;
}

@media (max-width: 420px) {
  table {
    font-size: 0.7rem;
  }
}

@media (min-width: 1020px) {
  .body__wrap {
    display: grid;
    grid-template-areas: "navi head head head head head" "navi main main main main main" "navi main main main main main" "navi main main main main main" "navi main main main main main";
    gap: 10px;
  }

  .main__content {
    grid-area: main;
    height: 75vh;
    width: 80vw;
  }

  .data__sections--wrap {
    grid-template-areas: "pie pie player player league league" "pie pie player player league league" "pie pie team team league league" "pie pie team team league league" "minute minute minute minute league league" "minute minute minute minute league league";
    gap: 10px;
    grid-template-columns: repeat(6, 1fr);
    grid-template-rows: repeat(6, 1fr);
  }

  .player__stats--wrap {
    height: 100%;
  }

  .team__stats--wrap {
    bottom: 0;
    height: 100%;
  }

  .minute__stat--percentage {
    margin-top: 3rem;
  }

  header {
    grid-area: head;
    height: 15vh;
    width: 80vw;
    grid-template-areas: "title title . . . navT" ". . . . . ." "name name . . search search";
  }

  .nav__top {
    width: auto;
    height: auto;
    padding: 0%;
    position: relative;
  }

  .theme__toggle--wrap {
    width: 100%;
  }

  .burger__wrap {
    display: none;
  }

  .title__logo {
    flex-direction: row;
    justify-content: flex-start;
  }

  .logo {
    display: none;
  }

  .title h1 {
    font-size: 1.5rem;
  }

  .search__wrap {
    justify-content: flex-end;
  }

  .search__input input {
    font-size: 0.9rem;
    padding: 5% 12%;
  }

  .team__name--wrap {
    grid-area: name;
    text-align: left;
  }
  .team__name--wrap h4 {
    font-size: 2.8rem;
  }

  .nav__slide {
    display: flex;
    width: 10rem;
    height: 100%;
    position: relative;
    grid-area: navi;
    border-radius: 20px;
    transform: translateX(0%);
    transition: none;
  }
  .nav__slide .slide__logo span {
    display: none;
  }

  .history__section--wrap {
    display: grid;
    display: none;
    grid-template-areas: "title title title img" "tex tex tex img" "tex tex tex img" "tex tex tex img";
  }

  .history__section--title {
    grid-area: title;
    padding: 5%;
  }

  .history__section--text {
    grid-area: tex;
    overflow-y: scroll;
  }

  .history__section--img {
    grid-area: img;
    padding: 0%;
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
  }
  .history__section--img img {
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
  }

  .prediction__section--wrap {
    grid-template-areas: ". title title ." "chart chart pred pred" "chart chart pred pred" "chart chart win win";
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: none;
    gap: 0px;
  }

  .prediction__section--winner {
    margin: 0;
    height: 100%;
  }

  .fixtures__section--content {
    height: 100%;
  }

  .league__table--content table {
    font-size: 0.7rem;
  }

  .league__table--name {
    bottom: 5px;
  }
}

@media (min-width: 1200px) {
  .team__stats--content .team__stats--box h6 {
    font-size: 0.7rem;
  }
}

.active__tab {
  display: grid;
}

.active__slide--nav {
  transform: translateX(0%);
  transition: 0.5s ease-in-out;
  height: 100%;
  width: 100%;
  backdrop-filter: blur(30px);
  -webkit-backdrop-filter: blur(30px);
}

.scrollbar__styles, .history__section--text::-webkit-scrollbar, .fixtures__section--content::-webkit-scrollbar {
  width: 20px;
}

.scrollbar-track__styles, .player__stats--wrap::-webkit-scrollbar-track, .history__section--text::-webkit-scrollbar-track, .fixtures__section--content::-webkit-scrollbar-track {
  background-color: var(--shade-color);
  border-radius: 40px;
  border-bottom-right-radius: 100px;
}

.scrollbar-thumb__styles, .player__stats--wrap::-webkit-scrollbar-thumb, .history__section--text::-webkit-scrollbar-thumb, .fixtures__section--content::-webkit-scrollbar-thumb {
  background-color: var(--flair-color);
  border-radius: 40px;
}

.overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 50;
  backdrop-filter: blur(20px);
  -webkit-backdrop-filter: blur(30px);
  display: none;
}

.error__msg--wrap {
  height: 50%;
  width: 85%;
  max-width: 595px;
  background-color: var(--error-color);
  border-radius: 25px;
  border: 2px solid var(--flair-color);
  color: var(--text--color);
  position: fixed;
  top: 50%;
  left: 50%;
  z-index: 100;
  transform: translate(-50%, -50%);
  display: none;
}

.error__msg--content {
  height: 100%;
  width: 100%;
  padding: 2%;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
}

.error__msg--content h4 {
  font-size: 1.5rem;
}

.error__close--icon {
  position: absolute;
  top: 15px;
  right: 15px;
}

.error__msg--icon svg {
  stroke: var(--text-color);
  transition: 1s;
}

.error__msg--text {
  text-align: center;
}

.error__msg--text span {
  display: block;
}

.error__msg--active {
  display: block;
}

.overlay__active {
  display: block;
}

.starter__menu--wrap {
  display: none;
  height: 60%;
  width: 85%;
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 75;
  transform: translate(-50%, -50%);
  background-color: var(--secondary-color);
}

.start__menu--content {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  text-align: center;
}

.starter__menu--icon svg {
  stroke: var(--flair-color);
}

.starter__menu--titles h4 {
  font-size: 2rem;
}

.starter__menu--titles h5 {
  font-size: 1.2rem;
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

html,
body {
  width: 100vw;
  transition: 1s;
}

body {
  background-color: var(--primary-color);
  font-family: "Kanit", sans-serif;
  color: var(--text-color);
  overflow-x: hidden;
}

.body__wrap {
  padding: 5%;
}

@media (min-width: 1020px) {
  .body__wrap {
    padding: 2%;
  }
}

/* Fonts */

/* kanit-200 - thai_latin */

@font-face {
  font-family: "Kanit";
  font-style: normal;
  font-weight: 200;
  src: url("kanit-v11-thai_latin-200.41d59183.eot");
  /* IE9 Compat Modes */
  src: local(""), url("kanit-v11-thai_latin-200.41d59183.eot#iefix") format("embedded-opentype"), url("kanit-v11-thai_latin-200.bf445e43.woff2") format("woff2"), url("kanit-v11-thai_latin-200.1642804a.woff") format("woff"), url("kanit-v11-thai_latin-200.a613ecce.ttf") format("truetype"), url("kanit-v11-thai_latin-200.0fadd06c.svg#Kanit") format("svg");
  /* Legacy iOS */
}

/* kanit-200italic - thai_latin */

@font-face {
  font-family: "Kanit";
  font-style: italic;
  font-weight: 200;
  src: url("kanit-v11-thai_latin-200italic.e02dace4.eot");
  /* IE9 Compat Modes */
  src: local(""), url("kanit-v11-thai_latin-200italic.e02dace4.eot#iefix") format("embedded-opentype"), url("kanit-v11-thai_latin-200italic.5b9340eb.woff2") format("woff2"), url("kanit-v11-thai_latin-200italic.2d275393.woff") format("woff"), url("kanit-v11-thai_latin-200italic.ed1f6153.ttf") format("truetype"), url("kanit-v11-thai_latin-200italic.dd055c71.svg#Kanit") format("svg");
  /* Legacy iOS */
}

/* kanit-300 - thai_latin */

@font-face {
  font-family: "Kanit";
  font-style: normal;
  font-weight: 300;
  src: url("kanit-v11-thai_latin-300.0054cd35.eot");
  /* IE9 Compat Modes */
  src: local(""), url("kanit-v11-thai_latin-300.0054cd35.eot#iefix") format("embedded-opentype"), url("kanit-v11-thai_latin-300.e8433eb1.woff2") format("woff2"), url("kanit-v11-thai_latin-300.5ae0e9f1.woff") format("woff"), url("kanit-v11-thai_latin-300.9a8165d3.ttf") format("truetype"), url("kanit-v11-thai_latin-300.816842a9.svg#Kanit") format("svg");
  /* Legacy iOS */
}

/* kanit-300italic - thai_latin */

@font-face {
  font-family: "Kanit";
  font-style: italic;
  font-weight: 300;
  src: url("kanit-v11-thai_latin-300italic.425e143b.eot");
  /* IE9 Compat Modes */
  src: local(""), url("kanit-v11-thai_latin-300italic.425e143b.eot#iefix") format("embedded-opentype"), url("kanit-v11-thai_latin-300italic.5bffcc4b.woff2") format("woff2"), url("kanit-v11-thai_latin-300italic.826561ea.woff") format("woff"), url("kanit-v11-thai_latin-300italic.7d638be3.ttf") format("truetype"), url("kanit-v11-thai_latin-300italic.bd35adde.svg#Kanit") format("svg");
  /* Legacy iOS */
}

/* kanit-regular - thai_latin */

@font-face {
  font-family: "Kanit";
  font-style: normal;
  font-weight: 400;
  src: url("kanit-v11-thai_latin-regular.78d3abeb.eot");
  /* IE9 Compat Modes */
  src: local(""), url("kanit-v11-thai_latin-regular.78d3abeb.eot#iefix") format("embedded-opentype"), url("kanit-v11-thai_latin-regular.cc9b9a4d.woff2") format("woff2"), url("kanit-v11-thai_latin-regular.8c64b923.woff") format("woff"), url("kanit-v11-thai_latin-regular.e915937f.ttf") format("truetype"), url("kanit-v11-thai_latin-regular.915457ad.svg#Kanit") format("svg");
  /* Legacy iOS */
}

/* kanit-italic - thai_latin */

@font-face {
  font-family: "Kanit";
  font-style: italic;
  font-weight: 400;
  src: url("kanit-v11-thai_latin-italic.84707a59.eot");
  /* IE9 Compat Modes */
  src: local(""), url("kanit-v11-thai_latin-italic.84707a59.eot#iefix") format("embedded-opentype"), url("kanit-v11-thai_latin-italic.7569dbd8.woff2") format("woff2"), url("kanit-v11-thai_latin-italic.53ca2bc5.woff") format("woff"), url("kanit-v11-thai_latin-italic.25f107fa.ttf") format("truetype"), url("kanit-v11-thai_latin-italic.7cee91cd.svg#Kanit") format("svg");
  /* Legacy iOS */
}

/* kanit-500 - thai_latin */

@font-face {
  font-family: "Kanit";
  font-style: normal;
  font-weight: 500;
  src: url("kanit-v11-thai_latin-500.90d59541.eot");
  /* IE9 Compat Modes */
  src: local(""), url("kanit-v11-thai_latin-500.90d59541.eot#iefix") format("embedded-opentype"), url("kanit-v11-thai_latin-500.b9aadd31.woff2") format("woff2"), url("kanit-v11-thai_latin-500.9731856b.woff") format("woff"), url("kanit-v11-thai_latin-500.4bc9cdc3.ttf") format("truetype"), url("kanit-v11-thai_latin-500.a76db092.svg#Kanit") format("svg");
  /* Legacy iOS */
}

/* kanit-500italic - thai_latin */

@font-face {
  font-family: "Kanit";
  font-style: italic;
  font-weight: 500;
  src: url("kanit-v11-thai_latin-500italic.9ba41beb.eot");
  /* IE9 Compat Modes */
  src: local(""), url("kanit-v11-thai_latin-500italic.9ba41beb.eot#iefix") format("embedded-opentype"), url("kanit-v11-thai_latin-500italic.636ac838.woff2") format("woff2"), url("kanit-v11-thai_latin-500italic.0a27f4ab.woff") format("woff"), url("kanit-v11-thai_latin-500italic.008402b5.ttf") format("truetype"), url("kanit-v11-thai_latin-500italic.95d61d55.svg#Kanit") format("svg");
  /* Legacy iOS */
}

/* kanit-600 - thai_latin */

@font-face {
  font-family: "Kanit";
  font-style: normal;
  font-weight: 600;
  src: url("kanit-v11-thai_latin-600.cac2131a.eot");
  /* IE9 Compat Modes */
  src: local(""), url("kanit-v11-thai_latin-600.cac2131a.eot#iefix") format("embedded-opentype"), url("kanit-v11-thai_latin-600.ba856172.woff2") format("woff2"), url("kanit-v11-thai_latin-600.d3c96373.woff") format("woff"), url("kanit-v11-thai_latin-600.f678e85c.ttf") format("truetype"), url("kanit-v11-thai_latin-600.76a35405.svg#Kanit") format("svg");
  /* Legacy iOS */
}

/* kanit-600italic - thai_latin */

@font-face {
  font-family: "Kanit";
  font-style: italic;
  font-weight: 600;
  src: url("kanit-v11-thai_latin-600italic.fc6950c5.eot");
  /* IE9 Compat Modes */
  src: local(""), url("kanit-v11-thai_latin-600italic.fc6950c5.eot#iefix") format("embedded-opentype"), url("kanit-v11-thai_latin-600italic.6cd1323b.woff2") format("woff2"), url("kanit-v11-thai_latin-600italic.23d2fa6d.woff") format("woff"), url("kanit-v11-thai_latin-600italic.0360b5d6.ttf") format("truetype"), url("kanit-v11-thai_latin-600italic.78e33ff4.svg#Kanit") format("svg");
  /* Legacy iOS */
}

/* kanit-700 - thai_latin */

@font-face {
  font-family: "Kanit";
  font-style: normal;
  font-weight: 700;
  src: url("kanit-v11-thai_latin-700.d85635a3.eot");
  /* IE9 Compat Modes */
  src: local(""), url("kanit-v11-thai_latin-700.d85635a3.eot#iefix") format("embedded-opentype"), url("kanit-v11-thai_latin-700.6d144194.woff2") format("woff2"), url("kanit-v11-thai_latin-700.15e8efb9.woff") format("woff"), url("kanit-v11-thai_latin-700.949683e7.ttf") format("truetype"), url("kanit-v11-thai_latin-700.5c68ec18.svg#Kanit") format("svg");
  /* Legacy iOS */
}

/* kanit-700italic - thai_latin */

@font-face {
  font-family: "Kanit";
  font-style: italic;
  font-weight: 700;
  src: url("kanit-v11-thai_latin-700italic.d48d6f45.eot");
  /* IE9 Compat Modes */
  src: local(""), url("kanit-v11-thai_latin-700italic.d48d6f45.eot#iefix") format("embedded-opentype"), url("kanit-v11-thai_latin-700italic.58b09bc3.woff2") format("woff2"), url("kanit-v11-thai_latin-700italic.62818f72.woff") format("woff"), url("kanit-v11-thai_latin-700italic.a6069164.ttf") format("truetype"), url("kanit-v11-thai_latin-700italic.51c6d766.svg#Kanit") format("svg");
  /* Legacy iOS */
}

/* kanit-800 - thai_latin */

@font-face {
  font-family: "Kanit";
  font-style: normal;
  font-weight: 800;
  src: url("kanit-v11-thai_latin-800.e0b4278a.eot");
  /* IE9 Compat Modes */
  src: local(""), url("kanit-v11-thai_latin-800.e0b4278a.eot#iefix") format("embedded-opentype"), url("kanit-v11-thai_latin-800.a02b07f1.woff2") format("woff2"), url("kanit-v11-thai_latin-800.80fce28f.woff") format("woff"), url("kanit-v11-thai_latin-800.d2c694bd.ttf") format("truetype"), url("kanit-v11-thai_latin-800.2f5d8d02.svg#Kanit") format("svg");
  /* Legacy iOS */
}

/* kanit-800italic - thai_latin */

@font-face {
  font-family: "Kanit";
  font-style: italic;
  font-weight: 800;
  src: url("kanit-v11-thai_latin-800italic.2b5c04d5.eot");
  /* IE9 Compat Modes */
  src: local(""), url("kanit-v11-thai_latin-800italic.2b5c04d5.eot#iefix") format("embedded-opentype"), url("kanit-v11-thai_latin-800italic.1065baa9.woff2") format("woff2"), url("kanit-v11-thai_latin-800italic.6de68b6d.woff") format("woff"), url("kanit-v11-thai_latin-800italic.e5591cfa.ttf") format("truetype"), url("kanit-v11-thai_latin-800italic.05603030.svg#Kanit") format("svg");
  /* Legacy iOS */
}

/* From uiverse.io by @adamgiebl */

.slnbutton {
  --green: #1BFD9C;
  font-size: 15px;
  padding: 0.7em 2.7em;
  letter-spacing: 0.06em;
  position: relative;
  font-family: inherit;
  border-radius: 0.6em;
  overflow: hidden;
  transition: all 0.3s;
  line-height: 1.4em;
  border: 2px solid var(--green);
  background: linear-gradient(to right, rgba(27, 253, 156, 0.1) 1%, transparent 40%, transparent 60%, rgba(27, 253, 156, 0.1) 100%);
  color: var(--green);
  box-shadow: inset 0 0 10px rgba(27, 253, 156, 0.4), 0 0 9px 3px rgba(27, 253, 156, 0.1);
  padding: 5px;
}

.slnbutton:hover {
  color: #82ffc9;
  box-shadow: inset 0 0 10px rgba(27, 253, 156, 0.6), 0 0 9px 3px rgba(27, 253, 156, 0.2);
}

.slnbutton:before {
  content: "";
  position: absolute;
  left: -4em;
  width: 4em;
  height: 100%;
  top: 0;
  transition: transform 0.5s ease-in-out;
  background: linear-gradient(to right, transparent 1%, rgba(27, 253, 156, 0.1) 40%, rgba(27, 253, 156, 0.1) 60%, transparent 100%);
}

.slnbutton:hover:before {
  transform: translateX(105em);
}

/* From uiverse.io by @alexruix */

.itemXCV {
  display: flex;
  flex-wrap: wrap;
}
/*# sourceMappingURL=index.7ad48e24.css.map */
