header {
  height: 115vh;
  width: 100%;
  display: grid;
  grid-template-areas:
    "navT navT navT navT"
    "navT navT navT navT"
    ". title title ."
    "search search search search"
    ". . . ."
    "name name name name";
  grid-template-rows: 1fr 1fr 1fr 1fr 1fr 1fr;
}

.nav__top {
  height: 10vh;
  width: 100vw;
  padding: 0 5%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  grid-area: navT;
  position: fixed;
  top: 0;
  right: 0;
  z-index: 2;
  background-color: var(--primary-color);
}

.burger__wrap {
  width: 15%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  span {
    font-size: 3rem;
  }
}

.theme__toggle--wrap {
  height: 2rem;
  width: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
}

.theme__toggle--btn {
  position: relative;
  background-color: var(--shade-color);
  // box-shadow: 100% black;
  border-radius: 20px;
  width: 100%;
  height: 100%;
  .dark__icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    opacity: 0;
    visibility: hidden;
    transition: 1s ease-out;
  }
  .light__icon {
    opacity: 0;
    visibility: hidden;
    transition: 1s ease-out;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

.theme__toggle--btn{
  .active__theme--icon{
    opacity: 1;
    visibility: visible;
    transition: 1s ease-out;
  }
}

.theme__toggle--btn:hover {
  cursor: pointer;
}

.title__logo {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  grid-area: title;
}

.logo{
  svg{
    width: 5rem;
    height: 5rem;
    stroke: var(--flair-color);
  }
}

.title {
  h1 {
    font-size: 3rem;
    font-weight: 400;
  }
  span {
    font-style: italic;
    color: var(--flair-color);
  }
}

.search__wrap {
  width: 100%;
  // height: 40%;
  grid-area: search;
  display: flex;
  justify-content: center;
  align-items: center;
}

.search__input {
  width: 90%;
  max-width: 360px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  .search__icon {
    position: absolute;
    left: 2%;
    font-size: 1.2rem;
  }
  .search__form{
    width: 100%;
  }
  input {
    width: 100%;
    color: var(--text-color);
    // text-align: center;
    padding: 5% 12%;
    border-radius: 40px;
    border: none;
    outline: none;
    background-color: var(--shade-color);
  }
}
::placeholder {
  color: var(--text-color);
}
.search__icon:hover{
  cursor: pointer;
}

.team__name--wrap{
  grid-area: name;
    width: 100%;
    text-align: center;
    // height: 5rem;
    h4{
        font-size: 2.8rem;
    }
}

// media queries

@media(min-width:375px){
.search__input{
  .search__icon{
    font-size: 1.5rem;
  }
}
}