@import "variables";
@import "dataSection";
@import "dataSectionTablet";
@import "mainSection";
@import "sideNav";
@import "header";
@import "headerTablet";
@import "historySection";
@import "fixturesSection";
@import "prediction";
@import "desktop";
@import "utilities";
@import "errorMsgs";
@import "starterMenu";

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

html,
body {
  // height: 100%;
  width: 100vw;
  transition: 1s;
}

body {
  background-color: var(--primary-color);
  font-family: "Kanit", sans-serif;
  color: var(--text-color);
  overflow-x: hidden;
}

.body__wrap {
  padding: 5%;
}

@media (min-width: 1020px) {
  .body__wrap {
    padding: 2%;
  }
}


/* Fonts */

/* kanit-200 - thai_latin */
@font-face {
  font-family: 'Kanit';
  font-style: normal;
  font-weight: 200;
  src: url('../fonts/kanit-v11-thai_latin-200.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('../fonts/kanit-v11-thai_latin-200.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/kanit-v11-thai_latin-200.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/kanit-v11-thai_latin-200.woff') format('woff'), /* Modern Browsers */
       url('../fonts/kanit-v11-thai_latin-200.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/kanit-v11-thai_latin-200.svg#Kanit') format('svg'); /* Legacy iOS */
}
/* kanit-200italic - thai_latin */
@font-face {
  font-family: 'Kanit';
  font-style: italic;
  font-weight: 200;
  src: url('../fonts/kanit-v11-thai_latin-200italic.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('../fonts/kanit-v11-thai_latin-200italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/kanit-v11-thai_latin-200italic.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/kanit-v11-thai_latin-200italic.woff') format('woff'), /* Modern Browsers */
       url('../fonts/kanit-v11-thai_latin-200italic.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/kanit-v11-thai_latin-200italic.svg#Kanit') format('svg'); /* Legacy iOS */
}
/* kanit-300 - thai_latin */
@font-face {
  font-family: 'Kanit';
  font-style: normal;
  font-weight: 300;
  src: url('../fonts/kanit-v11-thai_latin-300.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('../fonts/kanit-v11-thai_latin-300.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/kanit-v11-thai_latin-300.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/kanit-v11-thai_latin-300.woff') format('woff'), /* Modern Browsers */
       url('../fonts/kanit-v11-thai_latin-300.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/kanit-v11-thai_latin-300.svg#Kanit') format('svg'); /* Legacy iOS */
}
/* kanit-300italic - thai_latin */
@font-face {
  font-family: 'Kanit';
  font-style: italic;
  font-weight: 300;
  src: url('../fonts/kanit-v11-thai_latin-300italic.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('../fonts/kanit-v11-thai_latin-300italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/kanit-v11-thai_latin-300italic.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/kanit-v11-thai_latin-300italic.woff') format('woff'), /* Modern Browsers */
       url('../fonts/kanit-v11-thai_latin-300italic.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/kanit-v11-thai_latin-300italic.svg#Kanit') format('svg'); /* Legacy iOS */
}
/* kanit-regular - thai_latin */
@font-face {
  font-family: 'Kanit';
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/kanit-v11-thai_latin-regular.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('../fonts/kanit-v11-thai_latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/kanit-v11-thai_latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/kanit-v11-thai_latin-regular.woff') format('woff'), /* Modern Browsers */
       url('../fonts/kanit-v11-thai_latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/kanit-v11-thai_latin-regular.svg#Kanit') format('svg'); /* Legacy iOS */
}
/* kanit-italic - thai_latin */
@font-face {
  font-family: 'Kanit';
  font-style: italic;
  font-weight: 400;
  src: url('../fonts/kanit-v11-thai_latin-italic.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('../fonts/kanit-v11-thai_latin-italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/kanit-v11-thai_latin-italic.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/kanit-v11-thai_latin-italic.woff') format('woff'), /* Modern Browsers */
       url('../fonts/kanit-v11-thai_latin-italic.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/kanit-v11-thai_latin-italic.svg#Kanit') format('svg'); /* Legacy iOS */
}
/* kanit-500 - thai_latin */
@font-face {
  font-family: 'Kanit';
  font-style: normal;
  font-weight: 500;
  src: url('../fonts/kanit-v11-thai_latin-500.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('../fonts/kanit-v11-thai_latin-500.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/kanit-v11-thai_latin-500.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/kanit-v11-thai_latin-500.woff') format('woff'), /* Modern Browsers */
       url('../fonts/kanit-v11-thai_latin-500.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/kanit-v11-thai_latin-500.svg#Kanit') format('svg'); /* Legacy iOS */
}
/* kanit-500italic - thai_latin */
@font-face {
  font-family: 'Kanit';
  font-style: italic;
  font-weight: 500;
  src: url('../fonts/kanit-v11-thai_latin-500italic.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('../fonts/kanit-v11-thai_latin-500italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/kanit-v11-thai_latin-500italic.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/kanit-v11-thai_latin-500italic.woff') format('woff'), /* Modern Browsers */
       url('../fonts/kanit-v11-thai_latin-500italic.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/kanit-v11-thai_latin-500italic.svg#Kanit') format('svg'); /* Legacy iOS */
}
/* kanit-600 - thai_latin */
@font-face {
  font-family: 'Kanit';
  font-style: normal;
  font-weight: 600;
  src: url('../fonts/kanit-v11-thai_latin-600.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('../fonts/kanit-v11-thai_latin-600.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/kanit-v11-thai_latin-600.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/kanit-v11-thai_latin-600.woff') format('woff'), /* Modern Browsers */
       url('../fonts/kanit-v11-thai_latin-600.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/kanit-v11-thai_latin-600.svg#Kanit') format('svg'); /* Legacy iOS */
}
/* kanit-600italic - thai_latin */
@font-face {
  font-family: 'Kanit';
  font-style: italic;
  font-weight: 600;
  src: url('../fonts/kanit-v11-thai_latin-600italic.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('../fonts/kanit-v11-thai_latin-600italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/kanit-v11-thai_latin-600italic.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/kanit-v11-thai_latin-600italic.woff') format('woff'), /* Modern Browsers */
       url('../fonts/kanit-v11-thai_latin-600italic.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/kanit-v11-thai_latin-600italic.svg#Kanit') format('svg'); /* Legacy iOS */
}
/* kanit-700 - thai_latin */
@font-face {
  font-family: 'Kanit';
  font-style: normal;
  font-weight: 700;
  src: url('../fonts/kanit-v11-thai_latin-700.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('../fonts/kanit-v11-thai_latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/kanit-v11-thai_latin-700.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/kanit-v11-thai_latin-700.woff') format('woff'), /* Modern Browsers */
       url('../fonts/kanit-v11-thai_latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/kanit-v11-thai_latin-700.svg#Kanit') format('svg'); /* Legacy iOS */
}
/* kanit-700italic - thai_latin */
@font-face {
  font-family: 'Kanit';
  font-style: italic;
  font-weight: 700;
  src: url('../fonts/kanit-v11-thai_latin-700italic.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('../fonts/kanit-v11-thai_latin-700italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/kanit-v11-thai_latin-700italic.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/kanit-v11-thai_latin-700italic.woff') format('woff'), /* Modern Browsers */
       url('../fonts/kanit-v11-thai_latin-700italic.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/kanit-v11-thai_latin-700italic.svg#Kanit') format('svg'); /* Legacy iOS */
}
/* kanit-800 - thai_latin */
@font-face {
  font-family: 'Kanit';
  font-style: normal;
  font-weight: 800;
  src: url('../fonts/kanit-v11-thai_latin-800.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('../fonts/kanit-v11-thai_latin-800.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/kanit-v11-thai_latin-800.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/kanit-v11-thai_latin-800.woff') format('woff'), /* Modern Browsers */
       url('../fonts/kanit-v11-thai_latin-800.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/kanit-v11-thai_latin-800.svg#Kanit') format('svg'); /* Legacy iOS */
}
/* kanit-800italic - thai_latin */
@font-face {
  font-family: 'Kanit';
  font-style: italic;
  font-weight: 800;
  src: url('../fonts/kanit-v11-thai_latin-800italic.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('../fonts/kanit-v11-thai_latin-800italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/kanit-v11-thai_latin-800italic.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/kanit-v11-thai_latin-800italic.woff') format('woff'), /* Modern Browsers */
       url('../fonts/kanit-v11-thai_latin-800italic.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/kanit-v11-thai_latin-800italic.svg#Kanit') format('svg'); /* Legacy iOS */
}




/* From uiverse.io by @adamgiebl */
.slnbutton {
  --green: #1BFD9C;
  font-size: 15px;
  padding: 0.7em 2.7em;
  letter-spacing: 0.06em;
  position: relative;
  font-family: inherit;
  border-radius: 0.6em;
  overflow: hidden;
  transition: all 0.3s;
  line-height: 1.4em;
  border: 2px solid var(--green);
  background: linear-gradient(to right, rgba(27, 253, 156, 0.1) 1%, transparent 40%,transparent 60% , rgba(27, 253, 156, 0.1) 100%);
  color: var(--green);
  box-shadow: inset 0 0 10px rgba(27, 253, 156, 0.4), 0 0 9px 3px rgba(27, 253, 156, 0.1);
  padding: 5px;
 }
 
 .slnbutton:hover {
  color: #82ffc9;
  box-shadow: inset 0 0 10px rgba(27, 253, 156, 0.6), 0 0 9px 3px rgba(27, 253, 156, 0.2);
 }
 
 .slnbutton:before {
  content: "";
  position: absolute;
  left: -4em;
  width: 4em;
  height: 100%;
  top: 0;
  transition: transform .5s ease-in-out;
  background: linear-gradient(to right, transparent 1%, rgba(27, 253, 156, 0.1) 40%,rgba(27, 253, 156, 0.1) 60% , transparent 100%);
 }
 
 .slnbutton:hover:before {
  transform: translateX(105em);
 }

 /* From uiverse.io by @alexruix */
.itemXCV{
  display: flex;
  flex-wrap: wrap;
}







 