.fixtures__section--wrap{
    display: none;
    grid-template-areas:
    ". title title ."
    "cont cont cont cont"
    "cont cont cont cont"
    "cont cont cont cont"
    ;
    height: 100%;
    width: 100%;
    background-color: var(--secondary-color);
    border-radius: 20px;
}

.fixtures__section--title{
    grid-area: title;
    padding: 5%;
    width: 100%;
    text-align: center;
    font-size: 1.3rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.fixtures__section--content{
    grid-area: cont;
    width: 100%;
    height: 45rem;
    overflow: scroll;
    overflow-x: hidden;
    padding-left: 20px;
}

.fixtures__section--content::-webkit-scrollbar{
    @extend .scrollbar__styles;
    // width: 20px;
    // height: 100%;
}
.fixtures__section--content::-webkit-scrollbar-track{
    @extend .scrollbar-track__styles;
    // background-color: var(--shade-color);
    // border-radius: 20px;
    // border-bottom-right-radius: 100px;
}
.fixtures__section--content::-webkit-scrollbar-thumb{
    @extend .scrollbar-thumb__styles;
    // background-color: var(--flair-color);
    // border-radius: 20px;
}

.fixture{
background-color: var(--fixture-shade-color);
width: 75%;
margin: 15px auto;
padding: 1%;
font-size: 0.8rem;
position: relative;
display: flex;
flex-direction: column;
align-items: center;
border-radius: 60px;
}


// .fixture__competition{
// }

// .fixture__time{
// }

// .fixture__teams{
// }