.main__content{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

// .team__name--wrap{
//     width: 100%;
//     text-align: center;
//     // height: 5rem;
//     h4{
//         font-size: 2.8rem;
//     }
// }

