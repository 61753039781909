.data__sections--wrap {
  width: 100%;
  height: 100%;
  display: grid;
  display: none; // temp for now!
  gap: 1.5rem;
  grid-template-areas:
    "pie"
    "player"
    "team"
    "minute"
    "league";
  grid-template-rows: 22rem 20rem 20rem 20rem 1fr;
  grid-template-columns: 100%;
}

// PIECHART
.pie__chart--wrap {
  grid-area: pie;
  height: 100%;
  width: 100%;
  background-color: var(--secondary-color);
  border-radius: 20px;
  position: relative;
}

.pie__chart--content {
  position: relative;
  height: 100%;
  width: 100%;
  padding: 3%;
}

// PLAYER STATS
.player__stats--wrap {
  grid-area: player;
  height: 100%;
  width: 100%;
  border-radius: 20px;
  background-color: var(--secondary-color);
  overflow-y: scroll;
}

.player__stats--wrap::-webkit-scrollbar{
  // @extend .scrollbar__styles;
  width: 10px;
  border-radius: 20px;
  // overflow: hidden;
}

.player__stats--wrap::-webkit-scrollbar-track{
  @extend .scrollbar-track__styles;
}

.player__stats--wrap::-webkit-scrollbar-thumb{
  @extend .scrollbar-thumb__styles;
}

.player__stats--title {
  height: 15%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  h4 {
    // text-align: center;
    font-size: 0.8rem;
    font-weight: 500;
  }
}
.player__stats--table {
  width: 100%;
  height: 85%;
  font-size: 0.64rem;
  // padding: 0px 5px;
  table {
    width: 100%;
    height: 100%;
    border-collapse: collapse;
  }
  td,
  th {
    text-align: center;
    padding: 2px;
  }
}

.player__stats--body{
  tr:nth-child(odd) {
    background-color: var(--shade-color);
  }
}



// TEAM STATS
.team__stats--wrap {
  grid-area: team;
  height: 100%;
  width: 100%;
  background-color: var(--secondary-color);
  border-radius: 20px;
}
.team__stats--title {
  height: 15%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  h4 {
    font-size: 0.8rem;
    font-weight: 500;
  }
}
.team__stats--content {
  height: 85%;
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  .team__stats--box {
    text-align: center;
    height: 100%;
    width: 100%;
    padding: 2%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    h6{
      font-size: 0.6rem;
    }
  }
  .team__stats--box:nth-child(odd) {
    background-color: var(--shade-color);
  }
}

// MINUTE STATS
.minute__stat--wrap {
  grid-area: minute;
  height: 100%;
  width: 100%;
  padding: 2%;
  background-color: var(--secondary-color);
  border-radius: 20px;
  position: relative;
  z-index: -10;
}

.minute__stat--content {
  height: 100%;
  width: 100%;
  display: flex;
  // justify-content: center;
  // align-items: center;
}

.minute__stat--box {
  height: 100%;
  width: 50%;
  padding: 2%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  text-align: center;
  h4{
    font-size: 0.8rem;
    font-weight: 500;
  }
}

.minute__stat--percentage {
  position: relative;
  width: 140px;
  margin-top: 2rem;
  svg {
    fill: var(--flair-color);
    position: absolute;
    top: -120%;
    left: 5%;
    width: 8rem;
    height: 8rem;
    transition: 1s;
  }
}

// LEAGUE TABLE STATS

.league__table--wrap{
  grid-area: league;
  height: 100%;
  width: 100%;
  background-color: var(--secondary-color);
  border-radius: 20px;
  padding: 10px;
  position: relative;
    z-index: -10;
};

.league__table--name{
  text-align: center;
  height: 5%;
  position: relative;
  bottom: 5px;
};

.league__table--content{
  height: 100%;
  width: 100%;
  text-align: center;
  font-size: 0.8rem;
  table{
    width: 100%;
    height: 95%;
  }
}
