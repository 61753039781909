.active__tab{
    display: grid;
}

.active__slide--nav{
    transform: translateX(0%);
    transition: 0.5s ease-in-out;
    height: 100%;
    width: 100%;
    backdrop-filter: blur(30px); // might not work mobile
    -webkit-backdrop-filter: blur(30px); // this helps
}

// SECTION SCROLL BAR STYLES

.scrollbar__styles{
    width: 20px;
}

.scrollbar-track__styles{
    background-color: var(--shade-color);
    border-radius: 40px;
    border-bottom-right-radius: 100px;
}

.scrollbar-thumb__styles{
    background-color: var(--flair-color);
    border-radius: 40px;
}