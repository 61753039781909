


.starter__menu--wrap{
    display: none;
    height: 60%;
    width: 85%;
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 75;
    transform: translate(-50%,-50%);
    background-color: var(--secondary-color);
}

.start__menu--content{
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    text-align: center;
}

.starter__menu--icon{
    svg{
        stroke: var(--flair-color);
    }
}

.starter__menu--titles{
    h4{
        font-size: 2rem;
    }
    h5{
        font-size: 1.2rem;
    }
}