
@media(min-width:760px){
    .logo{
        img{
        width: 6rem;
        height: 6rem;
      }
    }
    .title{
        h1 {
        font-size: 4.5rem;
      }
    }
    .search__input{
        input{
            font-size: 1.1rem;
            padding: 5% 12%;
        }
        .search__icon {
            font-size: 1.4rem;
        }
    }
}
