@media (min-width: 1020px) {
  .body__wrap{
    // height: 100vh;
    display: grid;
    grid-template-areas:
      "navi head head head head head"
      // "navi head head head head head"
      "navi main main main main main"
      "navi main main main main main"
      "navi main main main main main"
      "navi main main main main main";
    gap: 10px;
    // grid-template-rows: 1fr 1fr 1fr 1fr 1fr 100% ;
  }

  // CONTENT AREA
  .main__content {
    grid-area: main;
    height: 75vh;
    width: 80vw;
  }

  // DATA SECTIONS
  .data__sections--wrap {
    grid-template-areas:
      "pie pie player player league league"
      "pie pie player player league league"
      "pie pie team team league league"
      "pie pie team team league league"
      "minute minute minute minute league league"
      "minute minute minute minute league league";
    // grid-template-rows: 1fr 1fr 1fr 1fr;
    gap: 10px;
    grid-template-columns: repeat(6,1fr);
    grid-template-rows: repeat(6,1fr);
  }
  
  .player__stats--wrap{
    height: 100%;
  }

  .team__stats--wrap{
    bottom: 0;
    height: 100%;
  }

  .minute__stat--percentage{
  margin-top: 3rem;
  }

  //****//
  // Header styles
  header {
    grid-area: head;
    height: 15vh;
    width: 80vw;
    grid-template-areas:
      "title title . . . navT"
      ". . . . . ."
      "name name . . search search";
    //   grid-template-rows: 1fr 1fr 1fr ;
  }
  .nav__top {
    width: auto;
    height: auto;
    padding: 0%;
    position: relative;
  }
  .theme__toggle--wrap {
    width: 100%;
  }
  .burger__wrap {
    display: none;
  }
  .title__logo {
    flex-direction: row;
    justify-content: flex-start;
  }
  .logo {
    display: none;
  }
  .title {
    h1 {
      font-size: 1.5rem;
    }
  }
  .search__wrap{
    justify-content: flex-end;
  }
  .search__input {
    input {
      font-size: 0.9rem;
      padding: 5% 12%;
    }
  }
  .team__name--wrap {
    grid-area: name;
    text-align: left;
    h4 {
      font-size: 2.8rem;
    }
  }
  //
  .nav__slide {
    display: flex;
    width: 10rem;
    height: 100%;
    position: relative;
    grid-area: navi;
    border-radius: 20px;
    transform: translateX(0%);
    transition: none;
    .slide__logo{
    span{
      display: none
    }
    }
  }

  // HISTORY SECTION
  .history__section--wrap{
  // min-height: 75vh;
  // width: 81vw;
  display: grid;
  display: none;// temp
  grid-template-areas:
  "title title title img"
  "tex tex tex img"
  "tex tex tex img"
  "tex tex tex img"
  ;
  }

  .history__section--title{
    grid-area: title;
    padding: 5%;
  }

  .history__section--text{
    grid-area: tex;
    overflow-y: scroll;
  }

  .history__section--img{
    grid-area: img;
    padding: 0%;
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
    img{
      border-top-right-radius: 20px;
      border-bottom-right-radius: 20px;
    }
  }

  // PREDICTION SECTION
  .prediction__section--wrap{
    grid-template-areas:
    ". title title ."
    "chart chart pred pred"
    "chart chart pred pred"
    "chart chart win win"
    ;
    grid-template-columns: repeat(4,1fr);
    grid-template-rows: none;
    gap: 0px;
    }

    .prediction__section--winner{
        margin: 0;
        height: 100%;
    }

    // FIXTURES SECTION
    .fixtures__section--content{
      height: 100%
    }

    // LEAGUE TABLE SECTION
    .league__table--content{
      table{
        font-size: 0.7rem
      }
    }

    .league__table--name{
        bottom: 5px;
    };
};

// LARGE DESKTOP

@media(min-width:1200px){
  .team__stats--content{
    .team__stats--box{
      h6{
        font-size: .7rem;
      }
    }
  }
}