// Light-Mode Colours
:root{
--primary-color: #2b2b2c;// rgb(242, 242, 248)
--secondary-color:rgba(34, 169, 227, 0.25);
--flair-color: #22A9E3; // rgb(34, 169, 227)
--text-color: #fffcfc; // rgb(14, 13, 13)
--shade-color:rgba(10,9,9, 0.05);
// might be better way!
--fixture-shade-color: rgba(34, 169, 227, 0.25);
--error-color: #22A9E3;
transition: 1s;
}

.dark-theme{
    // Dark-Mode Colours
    --primary-color: #131326; // rgb(19, 19, 38)
    --secondary-color: #222536;// rgb(34, 37, 54)
    --flair-color: #C65300; // rgb(198, 83, 0)
    --text-color:#F8F9FE; // rgb(248, 249, 254)  
    --shade-color:rgba(255,255,255, 0.05);
    --fixture-shade-color:rgba(255,255,255, 0.05);
    --error-color: #222536;
    transition: 1s;
}

// Font family
$font-poppins:'Poppins', sans-serif;