.nav__slide{
    // DISPLAY NONE = TEMP
    position: fixed;
    top: 0;
    left: 0;
    width: 40%;
    height: 100%;
    display: flex;
    transform: translateX(-100%);
    transition: 0.5s ease-in-out;
    // display: none;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    background-color: var(--secondary-color);
    // border-right: 2px solid $secondary-color;
    z-index: 30;
    ul{
        margin-bottom: auto;
        list-style-type: none;
        width: 100%;
        height: 65%;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: center;
        text-align: center;
        li{
            width: 100%;
            height: 15%;
            display: flex;
            justify-content:center;
            align-items: center;
            a{
                text-decoration: none;
                color: var(--text-color);
            }
        }
        li:hover{
            background-color: var(--secondary-color);
            cursor: pointer;
        }
    }
}

.slide__logo{
margin-bottom: auto;
width: 100%;
height: 15%;
display: flex;
flex-direction: column;
justify-content: space-evenly;
align-items: center;
position: relative;
svg{
    stroke: var(--flair-color);
}
span{
    font-size: 2.5rem;
    position: absolute;
    top: 10px;
    right: 15px;
}
}

.dateTime{
    border-top: 2px solid var(--secondary-color);
    width: 100%;
    height: 15%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    transition: none;
}
