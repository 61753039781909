.prediction__section--wrap{
    height: 100%;
    width: 100%;
    padding: 2%;
    display: none;
    background-color: var(--secondary-color);
    border-radius: 20px;
    grid-template-areas:
    ". title title ."
    "pred pred pred pred"
    "pred pred pred pred"
    "win win win win"
    "chart chart chart chart"
    "chart chart chart chart"
    ;
    row-gap: 60px;
    // -webkit-gap: 60px;
    // grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-template-rows: 50px repeat(5, minmax(100px, 1fr));
    // grid-auto-rows: 1fr;
    // -webkit-grid-template-rows: 50px repeat(4, 1fr);
}

.prediction__chart--wrap{
    grid-area: chart;
    height: 100%;
    width: 100%;
    padding: 1px;
}

.prediction__chart--radar{
    position: relative;
    height: 95%;
    width: 95%;
    margin: 0 auto;
}

.prediction__section--title{
    grid-area: title;
    width: 100%;
    height: 100%;
    text-align: center;
    padding-top: 2%;
    font-size: 1.5rem;
    // background-color: $secondary-color;
}

.prediction__section--content{
    grid-area: pred;
    height: 100%;
    width: 100%;
    padding: 1px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    // gap: 50px;
    table{
        height: 75%;
        width: 100%;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        th{
            padding:.5rem 0%;
        }
        td{
            // font-size: 0.8rem;
            padding: .5rem 0;
        }
    }
}

.prediction__section--winner{
    grid-area: win;
    height: 60%;
    width: 100%;
    font-size: 0.8rem;
     div{
    //     margin: 0 auto;
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        align-items: center;
        text-align: center;
    //     border-radius: 45px;
    //     background-color: var(--fixture-shade-color);
         height: 100%;
        width: 100%;
    //     max-width: 215px;
     }
}

// Mobile font size
@media(max-width:420px){
    table{
        font-size: 0.7rem
    }
};