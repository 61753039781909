
.overlay{
    position: fixed;
    width: 100%;
    height: 100%;
    z-index: 50;
    backdrop-filter: blur(20px);
    -webkit-backdrop-filter: blur(30px);
    // opacity: 0;
    // visibility: hidden;
    // transition: 1s ease-in;
    display:none;
}

.error__msg--wrap{
    height: 50%;
    width: 85%;
    max-width: 595px;
    background-color: var(--error-color);
    border-radius: 25px;
    border: 2px solid var(--flair-color);
    color: var(--text--color);
    position: fixed;
    top: 50%;
    left: 50%;
    z-index: 100;
    transform: translate(-50%,-50%);
    display: none;// temp
}

.error__msg--content{
    height: 100%;
    width: 100%;
    padding: 2%;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    h4{
        font-size: 1.5rem;
    }
}

.error__close--icon{
    position: absolute;
    top: 15px;
    right: 15px;
}

.error__msg--icon{
    svg{
        stroke:var(--text-color);
        transition: 1s;
    }
}

.error__msg--text{
    text-align: center;
    span{
        display: block
    }
}

// ACTIVE CLASSES

.error__msg--active{
    display: block;
};

.overlay__active{
    // opacity: 1;
    // visibility: visible;
    // transition: 1s ease-in;
    display: block;
}