.history__section--wrap{
    display: none;// temp
    width: 100%;
    height: 100%;
    background-color: var(--secondary-color);
    border-radius: 20px;
    grid-template-areas:
    ". t t ."
    "p p p p"
    "p p p p"
    "img img img img"
    "img img img img"
    ;
}

.history__section--title{
    // height: 10%;
    padding: 20%;
    // width: 100%;
    grid-area: t;
    text-align: center;
    font-size: 1.3rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

// .history__section--content{
//     height: 85%;
//     width: 100%;
//     padding: 0% 5%;
// }

.history__section--text{
    grid-area: p;
    // width: 100%;
    height: 100%;
    padding: 5%;
    // overflow-y: scroll;
}

.history__section--text::-webkit-scrollbar{
    @extend .scrollbar__styles;
}

.history__section--text::-webkit-scrollbar-track{
    @extend .scrollbar-track__styles;
}

.history__section--text::-webkit-scrollbar-thumb{
    @extend .scrollbar-thumb__styles;
}

.history__section--img{
    grid-area: img;
    // width: 100%;
    padding: 2% 5%;
    // height: 50%;
    img{
    height: 100%;
    width: 100%;
    object-fit: cover;
    -webkit-object-fit: cover;
    -moz-object-fit: cover;
    object-position: 100% 100%;
    -webkit-object-position: 100% 100%;
    -moz-object-position: 100% 100%;
    }
};